<template>
    <div class="DetailBox" @click="closeDetail">
        <!-- style="display: flex;justify-content: center;" -->
        <!--  style="display: flex;justify-content: space-between;" -->
        <div class="TabBox">
            <div>
                <div v-for="(TabList, index) in TabList" :key="index" style="background: rgba(3, 2, 34, 0.06);padding: 5vh  0.8vh;color:#fff;border-radius: 8px 0px 0px 8px;" :class="{ actived: TabIndex == index }" @click.stop="CheckTab(index)">
                    <div style="position: relative;top: -0.1rem;cursor: pointer; writing-mode: vertical-lr;">{{TabList.name}}</div>
                </div>
            </div>
        </div>
        <div class="ConBox" @click.stop="open">
            <!-- 内容切换 -->

            <!-- 简介内容 -->
            <div v-if="TabIndex == 0" style="width: 100%;height:80%;min-height:21rem; ;margin: 2rem 0;display: flex;justify-content: space-between;">
                <BriefIntroduction :Introduction="detail"></BriefIntroduction>
            </div>
            <!-- 图片内容 -->
            <div v-else-if="TabIndex == 1" style="width: 100%;min-height:53vh;display: flex;flex-direction: column;align-items: center;">
                <ImgList :ImgList="imgList"></ImgList>
            </div>
            <!-- 视频集 -->
            <div v-else-if="TabIndex == 2 && videoList.length>0" style="width: 100%;min-height:53vh;display: flex;flex-direction: column;align-items: center;margin-top: 2vh;">
                <VideoBox :list="videoList"></VideoBox>
            </div>

        </div>
    </div>
</template>
<script>
import ImgList from '../Scenicspace/components/imgList.vue'
import ImgSwiper from '../Scenicspace/components/ImgSwiper.vue'
// import VideoBox from '../Scenicspace/components/VideoBox.vue'
import VideoBox from '../Public/VideoBox.vue'
import BriefIntroduction from '../Scenicspace/components/BriefIntroduction.vue'
import $ from 'jquery'

export default {
    components: { ImgSwiper, VideoBox, BriefIntroduction, ImgList },
    props: ['detail'],
    data () {
        return {
            TabIndex: 0,
            TabList: [{ name: '简介' }, { name: '照片' }, { name: '视频' }],
            imgList: [],//图片集
            imgListShow: [],//展示的缩略图
            imgTotalPage: 0, //总页数
            imgPageNum: 5, //每页显示条数
            imgPage: 1, //当前页
            imgIdx: 0,//当前图片
            videoList: [],//视频集
            videoIdx: 0,
        }
    },
    mounted () {
        var axios = require('axios')
        var config = {
            method: 'get',
            url: this.$Schttp + '/vtp/app/ihInfo/detail/' + this.detail.heritageId,
            headers: {},
        }
        axios(config)
            .then((res) => {
                this.imgList = res.data.data.ihInfo.imgs || []
                this.imgTotalPage = Math.ceil(this.imgList.length / this.imgPageNum) || 1
                this.imgListShow = this.imgList.slice(0, this.imgPageNum)
                this.videoList = res.data.data.ihInfo.videos || []

                this.detailShow = true
            })
            .catch(function (error) { })
    },
    methods: {
        open () {

        },
        CheckTab (index) {
            this.TabIndex = index
            // this.TabList[0].img = 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenLeft.png'
            // this.TabList[1].img = 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenZhong.png'
            // this.TabList[2].img = 'http://oss-prod.lzxx8848.com/image/Scenicspace/RenWenRight.png'
            // if (index == 0) {
            //     this.TabList[index].img = this.TabListTwo[index].imgTwo
            // }
            // if (index == 1) {
            //     this.TabList[index].img = this.TabListTwo[index].imgTwo
            // }
            // if (index == 2) {
            //     this.TabList[index].img = this.TabListTwo[index].imgTwo
            // }
        },
        closeDetail () {

            $('.DetailBox').fadeOut(500)
            setTimeout(() => {
                this.$parent.detailShow = false
            }, 500)
        },

        // 切换视频
        changeVideoIdx (idx) {
            this.videoIdx = idx
        },
    }
}
</script>
<style lang="less" scoped>
@import '../../assets/unify.less';

.DetailBox {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    z-index: 999;
    .TabBox {
        margin: 8vh 0 0 15%;
        > div {
            margin: 1vh 0;
        }
    }
    .ConBox {
        background-color: rgba(255, 255, 255, 0.9);
        margin: 5vh 0;
        width: 60%;
        height: 90vh;
        padding: 0 2rem 1rem;
        border-radius: 10px;
        overflow-y: auto;
    }
    .actived {
        color: white !important;
        background: #000 !important;
    }
    @remvw: 1920 /100vw !important;

    ::-webkit-scrollbar {
        width: 7 / @remvw;
        /*对垂直流动条有效*/
        height: 7 / @remvw;
        /*对水平流动条有效*/
    }

    /*定义外层滚动槽*/
    ::-webkit-scrollbar-track-piece {
        border-radius: 6 / @remvw;
    }

    /*定义内层 滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb {
        border-radius: 6 / @remvw;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #8b8b8b;
        opacity: 0.3;
    }

    /*定义两端按钮的样式*/
    ::-webkit-scrollbar-button {
        display: none;
    }

    /*定义边角样式*/
    ::-webkit-scrollbar-corner {
        display: none;
    }
}
</style>