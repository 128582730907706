<template>
    <div id="wrap" v-if="ImgList.length>0">
        <ul>
            <li @click="liClick(item,index)" v-for="(item,index) in ImgList" :key="index">
                <img :src="item.resourceUrl" alt="">
                <div></div>
                <div></div>
                <div></div>
            </li>

        </ul>
    </div>
    <div id="shadowBox" @click="shadowBoxClick"></div>
    <div id="showPic">
        <div class="prev" @click="prevClick">
        </div>
        <div class="img">
            <div style="z-index: 1;background: #000;"> <img src="" /></div>
            <div style="z-index: 2"> <img src="" /></div>

        </div>
        <div class="next" @click="nextClick"></div>
    </div>
</template>
<script>
export default {
    props: ['ImgList'],
    data () {
        return {
            lis: '',
            shadowBox: '',
            showPic: '',
            prev: '',
            next: '',
            imgCon: '',
            bigImgs: '',
            canClick: true,
            // 自己的数据
            active: 0,
            imgs: []
        }
    },
    mounted () {
        this.lis = document.querySelectorAll('#wrap li')
        this.shadowBox = document.querySelector('#shadowBox')
        this.showPic = document.querySelector('#showPic')
        this.prev = document.querySelector('.prev')
        this.next = document.querySelector('.next')
        this.imgCon = document.querySelector('#showPic .img')
        this.bigImgs = this.imgCon.children
    },
    methods: {
        // 点击遮罩层
        shadowBoxClick () {
            shadowBox.style.display = showPic.style.display = 'none';
            shadowBox.style.opacity = showPic.style.opacity = 0;
            showPic.style.transform = 'scale(0)';
        },
        // 点击图片
        liClick (data, idx) {
            this.active = idx
            shadowBox.style.height = document.documentElement.offsetHeight + 'px';
            shadowBox.style.display = showPic.style.display = 'block';

            setTimeout(function () {
                shadowBox.style.opacity = showPic.style.opacity = 1;
                showPic.style.transform = 'scale(1)';
            }, 50);

            this.bigImgs[1].querySelector('img').src = this.ImgList[this.active].resourceUrl;

        },
        // 下一张
        nextClick () {
            let t = this
            let curNum = this.active

            var nextNum = 0;	//下一张图片的索引（背后那张图片的索引）
            if (!this.canClick) {
                return;
            }
            this.canClick = false;	//点击后立马变成false，再书合上后再变成true，只有为true的时候才能点

            nextNum = curNum + 1;

            if (nextNum == this.ImgList.length) {	//走到最后了，再回到起点
                nextNum = 0;
            }
            this.bigImgs[0].querySelector('img').src = this.ImgList[nextNum].resourceUrl;	//换后面那张图片的地址
            var endNum1 = 0;	//记录上面图片运动结束的次数
            var endNum2 = 0;	//记录下面图片运动结束的次数


            this.bigImgs[0].className = this.bigImgs[1].className = 'moveToRight';	//上面图往右走、下面图张开的时候，旋转不太对劲，是因为旋转中心没有设置
            this.bigImgs[1].style.transform = 'translateX(600px) rotateY(-10deg)';	//上面图片往右走
            this.bigImgs[1].addEventListener('transitionend', function () {	//上面图片已经走到右边了
                endNum1++;

                t.bigImgs[0].style.transform = 'rotateY(-10deg)';	//下面图张开
                t.bigImgs[1].style.transform = 'translateX(0) rotateY(0deg)';	//上面图回去
                if (endNum1 == 2) {	//这个条件成立说明，现在是上面的图上已经回到原点了
                    t.bigImgs[0].style.transform = 'rotateY(0)';	//下面的图合上
                    t.bigImgs[1].style.zIndex = 1;	//上面的图跑后面
                    t.bigImgs[0].style.zIndex = 2;	//下面的图跑前面
                }
            });


            //下面的图合上了
            this.bigImgs[0].addEventListener('transitionend', function () {
                endNum2++;
                if (endNum2 == 2) {
                    curNum++;
                    t.active++
                    if (curNum == t.ImgList.length) {	//走到最后了，再回到起点
                        curNum = 0;
                        t.active = 0
                    }
                    t.bigImgs[0].style.zIndex = 1;	//后面图片的zIndex
                    t.bigImgs[1].style.zIndex = 2;	//前面图片的zIndex，既然层级变了，那图片的路径也要变
                    t.bigImgs[1].querySelector('img').src = t.ImgList[nextNum].resourceUrl;	//只用变前面那张图片的地址，变成下一张图片的地址

                    t.canClick = t;	//书合上了，才可以进行下次的点击
                }
            })


        },
        // 上一张
        prevClick () {
            let t = this
            let curNum = t.active
            if (!t.canClick) {
                return;
            }
            t.canClick = false;
            let prevNum = curNum - 1;
            if (prevNum == -1) {
                prevNum = t.ImgList.length - 1;
            }
            t.bigImgs[0].querySelector('img').src = t.ImgList[prevNum].resourceUrl;
            var endNum1 = 0;
            var endNum2 = 0;
            t.bigImgs[0].className = t.bigImgs[1].className = 'moveToLeft';	//修改
            t.bigImgs[1].style.transform = 'translateX(-600px) rotateY(10deg)';	//修改
            t.bigImgs[1].addEventListener('transitionend', function () {
                endNum1++;
                t.bigImgs[0].style.transform = 'rotateY(10deg)';	//修改
                t.bigImgs[1].style.transform = 'translateX(0) rotateY(0deg)';
                if (endNum1 == 2) {
                    t.bigImgs[0].style.transform = 'rotateY(0)';
                    t.bigImgs[1].style.zIndex = 1;
                    t.bigImgs[0].style.zIndex = 2;
                }
            });

            t.bigImgs[0].addEventListener('transitionend', function () {
                endNum2++;
                if (endNum2 == 2) {
                    //这一块修改
                    curNum--;
                    t.active--
                    if (curNum == -1) {
                        curNum = t.ImgList.length - 1;
                        t.active = curNum
                    }

                    t.bigImgs[0].style.zIndex = 1;
                    t.bigImgs[1].style.zIndex = 2;
                    t.bigImgs[1].querySelector('img').src = t.ImgList[prevNum].resourceUrl;	//修改
                    t.canClick = true;
                }
            })
            // }

        },

    }
}
</script>
<style lang="less" scoped>
@import './css/index_copy.css';
</style>